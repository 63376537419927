"use client";
import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import Link from "next/link";
import { usePathname } from "next/navigation";

const HeaderMenu: React.FC = () => {
  /** 当前url */
  const pathname = usePathname();

  const [current, setCurrent] = useState<string[]>(["product"]);

  /** 导航栏数据 */
  const navs = [
    {
      key: "product",
      path: "",
      label: (
        <Link className="text-sm pc:text-base" href="/">
          产品
        </Link>
      )
    },
    {
      key: "eSystem",
      path: "/e-system",
      label: (
        <Link className="text-sm pc:text-base" href="/e-system">
          E+系统
        </Link>
      )
    },
    {
      key: "partner",
      path: "/partner",
      label: (
        <Link className="text-sm pc:text-base" href="/partner">
          合作伙伴
        </Link>
      )
    },
    {
      key: "support",
      path: "/support",
      label: (
        <Link className="text-sm pc:text-base" href="/support">
          服务支持
        </Link>
      )
    },
    {
      key: "about",
      path: "/about",
      label: (
        <Link className="text-sm pc:text-base" href="/about">
          关于我们
        </Link>
      )
    },
    {
      key: "/join",
      path: "/join",
      label: (
        <Link className="text-sm pc:text-base" href="/join">
          加入我们
        </Link>
      )
    }
  ];

  /** 获取tab默认值 */
  const getDefaultSelected = () => {
    if (!pathname || pathname === "/") {
      return ["product"];
    }
    let result = "";
    navs.forEach((item) => {
      if (pathname.includes(item.path)) {
        result = item.key;
      }
    });
    return result ? [result] : ["product"];
  };

  /** 初始化 */
  useEffect(() => {
    initSelected();
  }, []);

  /** 设置tab默认值 */
  const initSelected = () => {
    setCurrent(getDefaultSelected());
  };

  /** 点击tab */
  const onClick = (e: any) => {
    setCurrent(e.key);
  };

  return (
    <Menu
      style={{ minWidth: 0, flex: "auto" }}
      className="h-full"
      mode="horizontal"
      items={navs}
      selectedKeys={current}
      onClick={onClick}
    />
  );
};

export default HeaderMenu;
