"use client";
import React from "react";
import { Tooltip } from "antd";
import Image from "next/image";
import IconFont from "@/components/icon";
import { CALL_400 } from "@/utils/constants";

import styles from "./index.module.scss";

const Aside: React.FC = () => {
  /** 侧边栏功能 */
  const asideList = [
    {
      title: "电话沟通",
      icon: "/image/aside_call@2x.png",
      tip: (
        <div className="flex h-8 items-center px-2">
          <IconFont className="mr-2 text-base !text-[#7F8285] pc:text-xl" type="icon-dianhua-1" />
          <span className="text-black">{CALL_400}</span>
        </div>
      )
    },
    {
      title: "在线咨询",
      icon: "/image/aside_information@2x.png",
      tip: (
        <div className="flex flex-col items-center">
          <Image
            className="h-[135px] w-[135px] pc:h-[135px] pc:w-[135px]"
            src="/image/aside_qrcode@2x.png"
            width={135}
            height={135}
            alt="aside-icon"
          ></Image>
          <span className="text-black">微信扫码在线咨询</span>
        </div>
      )
    },
    {
      title: "回到顶部",
      icon: "/image/aside_back@2x.png",
      fn: () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    }
  ];
  return (
    <aside
      className={`${styles["component-wrapper"]} fixed right-3 top-1/2 z-[10] flex min-h-14 w-[60px] -translate-y-1/2 flex-col items-center justify-center rounded-full bg-white pb-4 shadow-lg pc:min-h-20 pc:w-20 pc:pb-5`}
    >
      {asideList.map((item: (typeof asideList)[0]) => {
        return (
          <div key={item.title}>
            <Tooltip className="hidden pc:block" placement="left" color="white" title={item.tip}>
              <div
                onClick={() => {
                  item.fn?.();
                }}
              >
                <div className="mt-4 flex flex-col items-center justify-center pc:mt-5">
                  <Image
                    className="h-9 w-9 pc:h-10 pc:w-10"
                    src={item.icon}
                    width={40}
                    height={40}
                    alt="aside-icon"
                  ></Image>
                  <div className="mt-1 text-xs text-[#666]">{item.title}</div>
                </div>
              </div>
            </Tooltip>
            <Tooltip className="pc:hidden" trigger="click" placement="left" color="white" title={item.tip}>
              <div
                onClick={() => {
                  item.fn?.();
                }}
              >
                <div className="mt-4 flex flex-col items-center justify-center pc:mt-5">
                  <Image
                    className="h-9 w-9 pc:h-10 pc:w-10"
                    src={item.icon}
                    width={40}
                    height={40}
                    alt="aside-icon"
                  ></Image>
                  <div className="mt-1 text-xs text-[#666]">{item.title}</div>
                </div>
              </div>
            </Tooltip>
          </div>
        );
      })}
    </aside>
  );
};

export default Aside;
